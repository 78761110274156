import { DiscardReasons, FieldDefinitionsDto, IdeaAttributeDto, IdeaDto, UserDto } from "api-shared";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../hooks/useLanguage";
import { formatUserFromId } from "../../../lib/formatters";
import { replaceImage } from "../../../lib/history";
import { replaceMentionUsers } from "../../../lib/mention";
import { useIdeaAttributeTranslations } from "./useIdeaAttributeTranslations";
import { useIdeaEstimateConversion } from "./useIdeaEstimates";
import { useIdeaStatusTranslation } from "./useIdeaStatusTranslation";

export function useIdeaDataFormatter(attributes: IdeaAttributeDto[], fieldDefinitions: FieldDefinitionsDto, users: UserDto[] = []) {
    const { t: translate } = useTranslation();
    const language = useLanguage();
    const conversion = useIdeaEstimateConversion();
    const attributeTranslations = useIdeaAttributeTranslations(attributes);
    const translateIdeaStatus = useIdeaStatusTranslation();

    function resolveIdeaFields(ideaFields: IdeaDto["fields"]): Record<string, unknown> {
        const fields: Record<string, unknown> = {};
        Object.keys(ideaFields).forEach((key) => {
            const field = fieldDefinitions[key];
            if (field === undefined || field.attributeName === undefined) {
                return;
            }
            fields[key] = attributeTranslations[key].find(({ id }) => id == ideaFields[key])?.value;
        });
        return fields;
    }

    function dataFormatter(data: IdeaDto[]) {
        return data.map((idea) => {
            const {
                potentialValue: potentialEstimate,
                timeValue: timeEstimate,
                effortValue: effortEstimate,
            } = conversion({
                potentialEstimateValue: idea.potentialEstimate,
                timeEstimateValue: idea.timeEstimate,
                effortEstimateValue: idea.effortEstimate,
            });

            const fields = resolveIdeaFields(idea.fields);

            const discardReason = idea.discardReason != null ? DiscardReasons.find(({ id }) => id == idea.discardReason) : null;
            const translatedDiscardReason = discardReason == null ? "" : discardReason[language];
            const ownerId = idea.ownerId != null ? formatUserFromId(Number(idea.ownerId), users, { translate }) : translate("deleted_user");

            const description = replaceImage(replaceMentionUsers(users, idea.description, translate));
            const discardStatement = idea.discardStatement != null ? replaceMentionUsers(users, idea.discardStatement, translate) : null;

            const { reactions, status, ...ideaWithoutReactionsAndStatus } = idea;

            const reactionsCount = reactions.length;

            const translatedStatus = translateIdeaStatus(status);

            return {
                ...ideaWithoutReactionsAndStatus,
                discardStatement,
                discardReason: translatedDiscardReason,
                description,
                ownerId,
                potentialEstimate,
                timeEstimate,
                effortEstimate,
                reactionsCount,
                status: translatedStatus,
                ...fields,
            };
        });
    }

    return {
        dataFormatter,
    };
}
