import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { Divider, Skeleton, Stack, Tooltip, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { IdeaDto } from "api-shared";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import MoneyChip from "../../components/MoneyChip";
import { translationKeys } from "../../translations/main-translations";
import { useIdeaPotentialSum } from "./hooks";

const SmallGreyInfoIcon = styled(InfoOutlined)(({ theme }) => ({
    verticalAlign: "-0.125em",
    color: theme.palette.fontLightGrey.main,
    fontSize: "inherit",
}));

interface IdeasSummaryProps {
    ideas: IdeaDto[];
    isFetching: boolean;
    isSortContainer?: boolean;
    numberOfHiddenIdeas?: number;
}

export const IdeasSummary = ({
    children,
    ideas,
    isFetching,
    isSortContainer,
    numberOfHiddenIdeas,
}: PropsWithChildren<IdeasSummaryProps>) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDenseLayout = useMediaQuery(theme.breakpoints.down("md"));
    const ideaPotentialSum = useIdeaPotentialSum(ideas);

    if (isFetching) {
        return <Skeleton height={44} variant="rounded" />;
    }

    return (
        <Stack
            p={1.25}
            direction={isDenseLayout ? "column" : "row"}
            justifyContent={isSortContainer ? "space-between" : undefined}
            alignItems="center"
            spacing={1}
        >
            {numberOfHiddenIdeas && numberOfHiddenIdeas !== 0 ? (
                <Typography align="center" flexGrow={1} flexBasis={0}>
                    <Typography component="span">
                        <strong> {ideas.length} </strong>
                        {t(translationKeys.VDLANG_IDEAS_ITEMS_LABEL)}
                    </Typography>
                    <Typography component="span" sx={{ color: theme.palette.fontLightGrey.main }}>
                        {` • `}
                        {t(translationKeys.VDLANG_IDEAS_MATRIX_HIDDEN_IDEAS_SUMMARY, { count: numberOfHiddenIdeas })}{" "}
                        <Tooltip title={t(translationKeys.VDLANG_IDEAS_MATRIX_HIDDEN_IDEAS_SUMMARY_HINT)}>
                            <SmallGreyInfoIcon />
                        </Tooltip>
                    </Typography>
                </Typography>
            ) : (
                <Typography align="center" flexGrow={1} flexBasis={0}>
                    <strong>{ideas.length} </strong>
                    {t(translationKeys.VDLANG_IDEAS_ITEMS_LABEL)}
                </Typography>
            )}

            <Divider orientation={isDenseLayout ? "horizontal" : "vertical"} flexItem />

            <Stack direction="row" alignItems="center" flexGrow={1} flexBasis={0}>
                <Typography align="center" flexGrow={1} sx={{ mr: 0.5 }}>
                    <MoneyChip short approx value={ideaPotentialSum} sx={{ mr: 0.5 }} />
                    {t(translationKeys.VDLANG_IDEAS_IDEA_POTENTIAL)}
                </Typography>
                {children}
            </Stack>
        </Stack>
    );
};
