import { EstimatesValue, IdeaDto } from "api-shared";
import { groupBy } from "lodash";
import { useCurrentClient } from "../../../domain/client";

export interface IUseIdeaPotentialSum {
    potentialSum: number;
}

export const useIdeaPotentialSum = (ideas: IdeaDto[] | undefined) => {
    const { ideaPotentialIntervals } = useCurrentClient();

    const groupedIdeas = groupBy(ideas, (ideas) => ideas.potentialEstimate);

    const firstInterval = ideaPotentialIntervals[0];
    const secondInterval = ideaPotentialIntervals[1];
    const thirdInterval = ideaPotentialIntervals[2];
    const fourthInterval = ideaPotentialIntervals[3];

    return (
        (groupedIdeas[EstimatesValue.VERY_LOW]?.length ?? 0) * (firstInterval / 2) +
        (groupedIdeas[EstimatesValue.LOW]?.length ?? 0) * ((secondInterval - firstInterval) / 2 + firstInterval) +
        (groupedIdeas[EstimatesValue.MEDIUM]?.length ?? 0) * ((thirdInterval - secondInterval) / 2 + secondInterval) +
        (groupedIdeas[EstimatesValue.HIGH]?.length ?? 0) * ((fourthInterval - thirdInterval) / 2 + thirdInterval) +
        (groupedIdeas[EstimatesValue.VERY_HIGH]?.length ?? 0) * fourthInterval
    );
};
